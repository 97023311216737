<template>
    <OColContainer class="position-relative">

        <Overlay v-if="isSearching" />


        <KeepAlive>
            <ODataGrid :data-object="dsCode" filter-row hideActionColumn initialMenuWidth="50%">
                <OColumn field="Type" width="150" sortable distinct="Type"></OColumn>
                <OColumn field="SubType" width="150" sortable distinct="SubType"></OColumn>
                <OColumn field="Name" width="275" sortable distinct="Name"
                    :cellrendererparams="{ href: '{{URL}}', target: '_code_search'}" v-slot="{ row, column }">
                    <OLink v-if="row.URL" :modelValue="row" :column="column"></OLink>
                    <template v-else>
                        {{row.Name}}
                    </template>
                </OColumn>
                <OColumn field="CodeObjectUpdated" headerName="Updated" width="150" sortable></OColumn>

                <template #detailTab v-if="IncludeCode">
                    <MonacoEditor v-if="dsCode.current.PrimKey" ref="monacoControl" formatJSON readonly
                        v-model="dsCode.current.Definition" :primKey="dsCode.current.PrimKey"
                        :lang="computeLang(dsCode.current)" />
                </template>
                <template #cardheader>
                    <div class="row">
                        <div class="col-4">
                                <input type="text" class="form-control form-control-sm" id="_floatingInput" v-focus
                                    placeholder="Search..."
                                    v-on:keyup.enter="doSearch" v-model="searchTerm">
                        </div>
                        <div class="col-8">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="_searchOpt.apps"
                                    v-model="searchOpt.apps">
                                <label class="form-check-label" for="_searchOpt.apps">Apps Files</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="_searchOpt.site"
                                    v-model="searchOpt.site">
                                <label class="form-check-label" for="_searchOpt.site">Static Files</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="_searchOpt.html"
                                    v-model="searchOpt.html">
                                <label class="form-check-label" for="_searchOpt.html">HTML</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="_searchOpt.sql" v-model="searchOpt.sql">
                                <label class="form-check-label" for="_searchOpt.sql">SQL</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="_searchOpt.ref" v-model="searchOpt.ref">
                                <label class="form-check-label" for="_searchOpt.ref">References</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="_searchOpt.ct" v-model="searchOpt.ct">
                                <label class="form-check-label" for="_searchOpt.ct">Include CT Code</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="_searchOpt.o365" v-model="searchOpt.o365">
                                <label class="form-check-label" for="_searchOpt.o365">O365 Articles</label>
                            </div>
                        </div>
                    </div>
                </template>
            </ODataGrid>
        </KeepAlive>
    </OColContainer>
</template>
<script setup>
import { getOrCreateProcedure, getOrCreateDataObject } from 'o365.vue.ts';
import MonacoEditor from 'o365.vue.components.MonacoEditor.vue';
import { ref } from 'vue';
import { userSession } from 'o365.modules.configs.ts';
import { Overlay } from 'o365.vue.components.jsx';

const procCleanUp = getOrCreateProcedure({ id: "procCleanUp", procedureName: "sstp_WebSiteCMS_CodeSearch_CleanUp" });
const procCodeSearchTsql = getOrCreateProcedure({ id: "procCodeSearchTsql", procedureName: "sstp_WebSiteCMS_CodeSearch_FindTsql", timeout:999999999 });
const procCodeSearchAppsFiles = getOrCreateProcedure({ id: "procCodeSearchAppsFiles", procedureName: "sstp_O365_CodeSearch_FindAppsFiles" });
const procCodeSearchStaticFiles = getOrCreateProcedure({ id: "procCodeSearchStaticFiles", procedureName: "sstp_O365_CodeSearch_FindStaticFiles" });
const procCodeSearchHtml = getOrCreateProcedure({ id: "procCodeSearchHtml", procedureName: "sstp_O365_CodeSearch_FindHTML" });

const procCodeSearchAppsFilesCT = getOrCreateProcedure({ id: "procCodeSearchAppsFilesCT", procedureName: "sstp_WebSiteCMS_CodeSearch_FindJS", timeout:999999999 });
const procCodeSearchCSharpCT = getOrCreateProcedure({ id: "procCodeSearchCSharpCT", procedureName: "sstp_WebSiteCMS_CodeSearch_FindCSHARP", timeout:999999999 });
const procCodeSearchHtmlCT = getOrCreateProcedure({ id: "procCodeSearchHtmlCT", procedureName: "sstp_WebSiteCMS_CodeSearch_FindHTML", timeout:999999999 });
const procCodeSearchReferences = getOrCreateProcedure({ id: "procCodeSearchReferences", procedureName: "sstp_WebSiteCMS_CodeSearch_FindReferences", timeout:999999999 })
const procCodeSearchO365 = getOrCreateProcedure({ id: "procCodeSearchO365", procedureName: "sstp_WebSiteCMS_CodeSearch_O365" })

const dsCode = getOrCreateDataObject({
    "id": "o365_dsCode",
    "viewName": "stbv_WebSiteCMS_CodeSearchResults",
    "fields": [{ "name": "Type" }, { "name": "Name" }, { "name": "Definition" }, { "name": "SubType" }, { "name": "URL" }, { "name": "CodeObjectUpdated", "sortOrder": 1, "sortDirection": "desc", type: "datetime" }, { name: "PrimKey" }]
})
let guid = null;
let crypto = window.crypto;
const monacoControl = ref(null);
const isSearching = ref(false);

const props = defineProps({
    Search: {
        type: String,
        default: null
    }, IncludeCode: {
        type: Boolean,
        default: false
    }, Sql: {
        type: Boolean,
        default: false
    }, Apps: {
        type: Boolean,
        default: false
    }, Html: {
        type: Boolean,
        default: false
    }, Site: {
        type: Boolean,
        default: false
    }, CT: {
        type: Boolean,
        default: false
    }, References: {
        type: Boolean,
        default: false
    }, o365Articles: {
        type: Boolean,
        default: false
    }

});

const searchTerm = ref(props.Search);
const searchOpt = ref({
    includeCode: props.IncludeCode,
    sql: props.Sql,
    apps: props.Apps,
    site: props.Site,
    html: props.Html,
    ct: props.CT,
    ref: props.References,
    o365: props.o365Articles
})

async function doSearch() {
    isSearching.value = true;
    await procCleanUp.execute({
        CreatedBy_ID: userSession.personId
    });
    guid = crypto.randomUUID();
    await Promise.all(getPromises());
    await reloadData();

    monacoControl.value?.getControl().openFind(searchTerm.value);
    isSearching.value = false;
}

function createProcPromise(proc, params) {
    return proc.execute(params);
}

function getPromises() {
    let promises = new Array();
    if (searchOpt.value.sql) {
        promises.push(createProcPromise(procCodeSearchTsql, { SearchTerm: searchTerm.value, Run: 1, UniqueKey: guid }));
        promises.push(createProcPromise(procCodeSearchTsql, { SearchTerm: searchTerm.value, Run: 2, UniqueKey: guid }));
        promises.push(createProcPromise(procCodeSearchTsql, { SearchTerm: searchTerm.value, Run: 3, UniqueKey: guid }));
        promises.push(createProcPromise(procCodeSearchTsql, { SearchTerm: searchTerm.value, Run: 4, UniqueKey: guid }));
        promises.push(createProcPromise(procCodeSearchTsql, { SearchTerm: searchTerm.value, Run: 5, UniqueKey: guid }));
    }
    if (searchOpt.value.html) {
        promises.push(createProcPromise(procCodeSearchHtml, { SearchTerm: searchTerm.value, UniqueKey: guid }));
        if (searchOpt.value.ct) {
            promises.push(createProcPromise(procCodeSearchHtmlCT, { SearchTerm: searchTerm.value, UniqueKey: guid }));
        }
    }
    if (searchOpt.value.apps) {
        promises.push(createProcPromise(procCodeSearchAppsFiles, { SearchTerm: searchTerm.value, UniqueKey: guid }));
        if (searchOpt.value.ct) {
            promises.push(createProcPromise(procCodeSearchAppsFilesCT, { SearchTerm: searchTerm.value, UniqueKey: guid }));
        }
    }
    if (searchOpt.value.site) {
        promises.push(createProcPromise(procCodeSearchStaticFiles, { SearchTerm: searchTerm.value, UniqueKey: guid }));
        if (searchOpt.value.ct) {
            promises.push(createProcPromise(procCodeSearchCSharpCT, { SearchTerm: searchTerm.value, UniqueKey: guid }));
        }
    }
    if (searchOpt.value.ref) {
        if (searchOpt.value.ct) {
            promises.push(createProcPromise(procCodeSearchReferences, { SearchTerm: searchTerm.value, UniqueKey: guid, IncludeCT: 1 }))
        } else {
            promises.push(createProcPromise(procCodeSearchReferences, { SearchTerm: searchTerm.value, UniqueKey: guid, IncludeCT: 0 }))
        }
    }
    if (searchOpt.value.o365) {
        promises.push(createProcPromise(procCodeSearchO365, { SearchTerm: searchTerm.value, UniqueKey: guid}))   
    }

    return promises;
}

async function reloadData() {
    dsCode.recordSource.whereClause = `UniqueKey = '${guid}'`;
    await dsCode.load();
    dsCode.setCurrentIndex(0);
}
function getExtensionByName(name) {
    var extension = name.substr(name.lastIndexOf('.') + 1);
    switch (extension.toLowerCase()) {
        case "js":
            extension = "javascript";
            break;
        case "json":
            extension = "json";
            break;
        case "ts":
            extension = "typescript";
            break;
        case "vue":
            extension = "html";
            break;
    }

    return extension;
}

function computeLang(item) {
    if (!item?.Type) { return; }
    let lang = "plaintext";
    switch (item.Type.toLowerCase()) {
        case "sql":
            lang = "sql";
            break;
        case "json":
            lang = "json";
            break;
        case "template":
        case "html":
            lang = "razor";
            break;
        case "csharp":
            lang = "csharp";
            break;
        case "appsfiles":
            lang = getExtensionByName(item.Name)
            break;
        case "staticfiles":
            lang = getExtensionByName(item.Name)
            break;
        default:
            lang = getExtensionByName(item.Name)
    }

    return lang;
}

if (props.Search) {
    doSearch();
}
</script>